<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow
        width="600"
        height="180"
        :steps="workflowSteps"
        :currentStep="workflowIndex"
        :labelWidth="200"
        :lineWidth="140"
      ></Workflow>
    </div>

    <v-row justify="center">
      <v-col xl="8" lg="8" md="10" sm="12" xs="12">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="Élément d'une foire aux questions" />
        </div>

        <!-- la progess bar à afficher lors du chargement des données -->
        <v-progress-linear indeterminate :active="loading"></v-progress-linear>

        <DetailViewBoxComponent title="Détail d'un élément de la foire aux questions">
          <template v-slot:items>
            <InputFieldComponent :cols="colsDetails" label="activité" :value="activityName" />
            <InputFieldComponent v-if="subActivityId" :cols="colsDetails" label="sous-activité" :value="subActivityName" />

            <!-- La question -->
            <InputFieldComponent :cols="colsDetails" label="question" alignLabel="start" >
              <template v-slot:value>
                <v-row no-gutters>
                  <v-col cols="6">
                    <div class="mr-2">{{ question }}</div>
                  </v-col>
                  <v-col cols="6">
                    <div
                      class="font-weight-medium"
                      style="background-color: #EEEEEE;">
                      <span v-html="previewQuestion"></span>
                    </div>
                  </v-col>
                </v-row>
              </template>
            </InputFieldComponent>

            <!-- La réponse -->
            <InputFieldComponent :cols="colsDetails" label="réponse" alignLabel="start" >
              <template v-slot:value>
                <v-row no-gutters>
                  <v-col cols="6" >
                    <div class="mr-2">{{ answer }}</div>
                  </v-col>
                  <v-col cols="6" >
                    <div
                      class="font-weight-medium"
                      style="background-color: #EEEEEE;">
                      <span 
                      v-html="previewAnswer"></span>
                    </div>
                  </v-col>
                </v-row>
              </template>
            </InputFieldComponent>

             <InputFieldComponent :cols="colsDetails" label="Poids" :value="weight" :divider="false" />
          </template>
        </DetailViewBoxComponent>

      </v-col>
    </v-row>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import DetailViewBoxComponent from "@/components/ui/DetailViewBoxComponent.vue";
import InputFieldComponent from "@/components/ui/InputFieldComponent.vue";

import { FaqActivityService } from "@/service/conf/faq_activity_service.js";
import { FaqSubActivityService } from "@/service/conf/faq_subactivity_service.js";
import { ActivityService } from "@/service/sfr/activity_service.js";
import SubActivitiesService from "@/service/sfr/sub_activity_service.js";

import * as exceptions from "@/service/exception_to_message.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { marked } from 'marked';


export default {
  name: "DetailFaq",

  components: { Workflow, TitleAndReturnComponent, DetailViewBoxComponent,
    InputFieldComponent, },
  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /** le service de la vue */
      serviceFaqActivity: null,
      serviceFaqSubActivity: null,
      serviceActivity: null,
      serviceSubActivity: null,

      /**l'identifiant  à visualiser. */
      entityId: null,
      activityId: null,
      subActivityId: null,

      /**Afficher le bouton de retour */
      showBackButton: false,

      /**les champs à afficher en détail */
      activityName: null,
      subActivityName: null,
      question: null,
      answer: null,
      weight: null,

      colsDetails: [3, 9],

    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        // Récupération de l'activité si existante
        this.activityName = "-";
        if (this.activityId) {
          let activity = await this.serviceActivity.getActivityById(this.activityId);
          this.activityName = activity.name;
        }

        // Récupération de la sous-activité si existante
        this.subActivityName = "-";
        if (this.subActivityId) {
          let subActivity = await this.serviceSubActivity.getSubActivityById(this.subActivityId);
          this.subActivityName = subActivity.label;
        }

        let entity = null;
        // Récupération de la faq en fonction de sa provenance
        if (this.subActivityId) {
          entity = await this.serviceFaqSubActivity.getById(this.entityId);
        } else {
          entity = await this.serviceFaqActivity.getById(this.entityId);
        }

        if (entity) {
          this.question = entity.question;
          this.answer = entity.answer;
          this.weight = entity.weight;
        }
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    
  },
  computed: {
    /** Prévisualisation en markdown de la question */
    previewQuestion() {
      if (!this.question) {
        return "";
      }
      return marked(this.question);
    },

    /** Prévisualisation en markdown de la réponse */
    previewAnswer() {
      if (!this.answer) {
        return "";
      }
      return marked(this.answer);
    },
  },
  mounted() {

    // Instanciation des services
    this.serviceFaqActivity = new FaqActivityService(this.$api.getFaqActivity());
    this.serviceFaqSubActivity = new FaqSubActivityService(this.$api.getFaqSubActivity());
    this.serviceActivity = new ActivityService(this.$api);
    this.serviceSubActivity = new SubActivitiesService(this.$api);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.entityId = this.$route.params.id;
    }
    // Récupération des paramètres de la route de l'objet
    if (this.$route.query) {
      if (this.$route.query.activity) {
        this.activityId = this.$route.query.activity;
      }
      if (this.$route.query.subactivity) {
        this.subActivityId = this.$route.query.subactivity;
      }
    }
    

    //on affiche le bouton retour
    this.showBackButton = true;

    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
</style>